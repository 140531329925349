import {Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {QueryParamsApiModel} from "../../api/models/query-params-api.model";
import {FilterParameterApiModel} from "../../api/models/filter-parameter.api.model";
import {Params} from "@angular/router";

@Injectable()
export class QueryParamsService {
  constructor() {
  }

  public applyParameters(queryParams: QueryParamsApiModel = new QueryParamsApiModel()): HttpParams {
    let params = new HttpParams();
    if (queryParams.filters) {
      let i = 0;
      let type = '';
      for (const filter of queryParams.filters) {
        type = filter.type === 'array_with_key' ? `[${filter.key}]` : type;
        type = filter.type === 'array' ? '[]' : type;
        type = filter.type === 'single' ? '' : type;
        params = params.append(`${filter.name}${type}`, filter.value);
        i++;
      }
    }
    if (queryParams.orderDirection) {
      if (typeof queryParams.orderDirection === 'number') {
        QueryParamsService.mapOrderDirection(queryParams);
      }
      params = params.append('orderDirection', queryParams.orderDirection);
    }
    if (queryParams.page) {
      params = params.append('page', String(queryParams.page));
    }
    if (typeof queryParams.pagination !== 'undefined') {
      params = params.append('pagination', Boolean(queryParams.pagination));
    }
    if (queryParams.limit) {
      params = params.append('limit', String(queryParams.limit));
    }
    return params;
  }

  private static mapOrderDirection(queryParams: QueryParamsApiModel): void {
    switch (queryParams.orderDirection) {
      case -1:
        queryParams.orderDirection = 'DESC';
        break;
      case 1:
        queryParams.orderDirection = 'ASC';
        break;
      default:
        queryParams.orderDirection = 'DESC';
    }
  }

  private parseValue(value: any): any {
    if (typeof value !== 'string') {
      return JSON.stringify(value);
    }
    return value;
  }

  public static updateQueryParametersFromSnapshot(queryParams: QueryParamsApiModel, params: Params): void {
    const queryParamsSnapshot = params;
    queryParams.filters = [];
    Object.keys(queryParamsSnapshot).forEach((key: string) => {
      const type = key.endsWith('[]') ? 'array' : 'single';
      if (queryParamsSnapshot[key] instanceof Array) {
        queryParamsSnapshot[key].forEach((item: string) => {
          queryParams.filters.push(new FilterParameterApiModel(key.replace('[]', ''), item, type));
        })
      } else {
        queryParams.filters.push(new FilterParameterApiModel(key, queryParamsSnapshot[key], 'single'));
      }
    });
  }
}

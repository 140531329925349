import {SelectItem} from "primeng/api";

export enum ColorEnum {
  BLACK = 'black',
  JET_BLACK = 'jet_black',
  MATTE_BLACK = 'matte_black',
  CARBON_BLACK = 'carbon_black',
  WHITE = 'white',
  PEARL_WHITE = 'pearl_white',
  IVORY_WHITE = 'ivory_white',
  RED = 'red',
  DARK_RED = 'dark_red',
  CRIMSON_RED = 'crimson_red',
  CHERRY_RED = 'cherry_red',
  BURGUNDY = 'burgundy',
  BLUE = 'blue',
  DARK_BLUE = 'dark_blue',
  LIGHT_BLUE = 'light_blue',
  NAVY_BLUE = 'navy_blue',
  SKY_BLUE = 'sky_blue',
  COBALT_BLUE = 'cobalt_blue',
  ELECTRIC_BLUE = 'electric_blue',
  GREEN = 'green',
  DARK_GREEN = 'dark_green',
  LIME_GREEN = 'lime_green',
  FOREST_GREEN = 'forest_green',
  OLIVE_GREEN = 'olive_green',
  MINT_GREEN = 'mint_green',
  TEAL = 'teal',
  YELLOW = 'yellow',
  CANARY_YELLOW = 'canary_yellow',
  MUSTARD_YELLOW = 'mustard_yellow',
  ORANGE = 'orange',
  DARK_ORANGE = 'dark_orange',
  BURNT_ORANGE = 'burnt_orange',
  COPPER = 'copper',
  BROWN = 'brown',
  DARK_BROWN = 'dark_brown',
  LIGHT_BROWN = 'light_brown',
  MOCHA = 'mocha',
  BRONZE = 'bronze',
  PURPLE = 'purple',
  DARK_PURPLE = 'dark_purple',
  LAVENDER = 'lavender',
  VIOLET = 'violet',
  PINK = 'pink',
  HOT_PINK = 'hot_pink',
  ROSE_PINK = 'rose_pink',
  MAGENTA = 'magenta',
  OTHER = 'other',
  GREY = 'grey',
  DARK_GREY = 'dark_grey',
  LIGHT_GREY = 'light_grey',
  SILVER = 'silver',
  MATTE_GREY = 'matte_grey',
  BEIGE = 'beige',
  CHAMPAGNE = 'champagne',
  GOLD = 'gold',
  ROSE_GOLD = 'rose_gold',
  PLATINUM = 'platinum',
  TITANIUM = 'titanium',
  CHROME = 'chrome',
  PEARL = 'pearl',
  MIDNIGHT_BLUE = 'midnight_blue',
  GRAPHITE = 'graphite',
}

export function getTranslatableColorOptions(): SelectItem[] {
  return Object.values(ColorEnum).map((color) => ({
    label: `common.color.${color}`,
    value: color,
  }));
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup, Validators} from "@angular/forms";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {AboutDataProviderService} from "../../../../features/user/project/about/about.data-provider.service";
import {ProjectViewModel} from "../../../../features/user/garage/project.view-model";
import {HistoryPostViewModel} from "../../../../features/user/project/about/history-post.view-model";
import {ModalStateEvent} from "../../modal-state-event";
import {ModalState} from "../../modal-state.enum";
import {ModalInterface} from "../../modal.interface";
import {Observable, Subject} from "rxjs";
import {state, style, trigger} from '@angular/animations';
import {SelectItem} from "primeng/api";
import {GarageDataProviderService} from "../../../../features/user/garage/garage.data-provider.service";
import {CurrentUserViewModel} from "../../../../core/models/current-user.view-model";
import {VehicleViewModel} from "../../../../features/user/garage/vehicle.view-model";
import {GarageStatusEnum} from "../../../../features/user/garage/garage-status.enum";
import {ModalComponent} from "../../modal.component";
import {AdvertisementTypeEnum} from "../../../../core/enums/advertisement-type.enum";
import {AdvertisementPublicationPeriodEnum} from "../../../../core/enums/advertisement-publication-period.enum";
import {ResultListApiModel} from "../../../../api/models/result-list.api.model";
import {EquipmentCategoryRestService} from "../../../../api/services/equipment-category.rest.service";
import {EquipmentCategoryApiModel} from "../../../../api/models/equipment-category.api-model";
import {AutoCompleteSelectEvent} from "primeng/autocomplete";
import {MapService} from "../../../map/map.service";
import {MapComponent} from "../../../map/map.component";
import {AdvertisementViewModel} from "../../../../features/marketplace/advertisement.view-model";
import {VehicleAttributes} from "../../../../core/interfaces/vehicle-attributes";
import {MarketplaceAdvertisementRestService} from "../../../../api/services/marketplace-advertisement.rest.service";
import {MarketplaceAdvertisementApiModel} from "../../../../api/models/marketplace-advertisement.api.model";
import {ProjectApiModel} from "../../../../api/models/project.api-model";
import {EquipmentApiModel} from "../../../../api/models/equipment.api-model";
import {MediaUploaderComponent} from "../../../media/media-uploader/media-uploader.component";
import {MediaUploadService} from "../../../../core/services/media-upload.service";
import {MediaObjectApiModel} from "../../../../api/models/media-object.api-model";
import {getTranslatableColorOptions} from "../../../../core/enums/color.enum";

@Component({
  selector: 'app-add-vehicle-advertisement',
  templateUrl: './add-vehicle-advertisement.component.html',
  styleUrls: ['./add-vehicle-advertisement.component.scss'],
  animations: [
    trigger('tabBar', [
      state('register', style({
        width: '33.3333%',
        left: '0'
      })),
      state('tier', style({
        width: '33.3333%',
        left: '33.3333%'
      })),
      state('payment', style({
        width: '33.3333%',
        left: '66.6667%'
      }))
    ])
  ],
  providers: [GarageDataProviderService, AboutDataProviderService]
})
export class AddVehicleAdvertisementComponent implements ModalInterface, OnInit {

  activeTab = 'register';

  activeCard = '';

  dropdownOptions1: SelectItem[];

  dropdownOptions2: SelectItem[];

  selectedProjectVM: ProjectViewModel = null;
  selectedColour: SelectItem<string> = null;

  selectDropdownOptions2: any;

  birthdate!: Date;

  isRelatedToExistingVehicle = true;
  isAnonymousAdvertisement = false;
  createNewProject = true;
  showHistory = false;
  showInvestments = false;
  showRankingPosition = false;
  showProfileLink = false;
  showModifiedParameters = false;

  radioButton1!: string;
  mediaProgress: number | null = null;

  data: any;
  parent: ModalComponent;
  subject: Subject<ModalStateEvent>;
  state: Observable<ModalStateEvent>;
  currentUserVM: CurrentUserViewModel;

  @Input() public projectVMs: ProjectViewModel[] = [];
  @Input() public historyPostVM: HistoryPostViewModel;
  @Output() onAdd: EventEmitter<HistoryPostViewModel> = new EventEmitter();
  @ViewChild('container') scrollContainer!: ElementRef;
  @ViewChild(MapComponent) public mapComponent: MapComponent;
  @ViewChild(MediaUploaderComponent) public mediaUploaderComponent: MediaUploaderComponent;
  public form: UntypedFormGroup;
  public readonly AdvertisementTypeEnum = AdvertisementTypeEnum;
  public readonly AdvertisementPublicationPeriodEnum = AdvertisementPublicationPeriodEnum;
  public filteredProjects: ProjectViewModel[] = [];
  public advertisementType: AdvertisementTypeEnum = AdvertisementTypeEnum.VEHICLE_SALE;
  public advertisementPublicationPeriod: AdvertisementPublicationPeriodEnum = AdvertisementPublicationPeriodEnum.ONE_MONTH;
  public isSubmitted: boolean = false;
  private initialAdvertisementName: string;
  public equipmentCategoryCollection: EquipmentCategoryApiModel[] = [];
  public searchResults: SelectItem[] = [];
  public readonly colours: SelectItem[] = getTranslatableColorOptions();

  constructor(
    private readonly fb: RxFormBuilder,
    private readonly garageDPS: GarageDataProviderService,
    private readonly equipmentCategoryRS: EquipmentCategoryRestService,
    private readonly marketplaceAdvertisementRS: MarketplaceAdvertisementRestService,
    private readonly mapService: MapService,
    private readonly mediaUploadService: MediaUploadService,
  ) { }

  ngOnInit(): void {
    this.initialAdvertisementName = this.parent.name;
    this.currentUserVM = this.data['currentUserVM'];
    this.equipmentCategoryRS.getCollection().subscribe((resultListAM: ResultListApiModel<EquipmentCategoryApiModel>) => {
      this.equipmentCategoryCollection = resultListAM.records;
      console.log(this.equipmentCategoryCollection);
    });
    this.garageDPS.fetchResources(this.currentUserVM).subscribe((projectVMs: ProjectViewModel[]) => {
      this.projectVMs = projectVMs;
      this.filteredProjects = projectVMs;
    });
    this.createForm();
  }

  private createForm() {
    const validators: Validators[] = [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})];
    const searchValidators = [
      RxwebValidators.required(),
      RxwebValidators.minLength({value: 2}),
    ];

    this.form = this.fb.group(
      {
        title: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return this.advertisementType === AdvertisementTypeEnum.SERVICE } })],
        description: ['', [RxwebValidators.minLength({value: 10}), RxwebValidators.maxLength({value: 65000})]],
        price: [null, validators],
        currency: [null, RxwebValidators.required()],
        priceValue: [null, RxwebValidators.required()],
        address: [null, searchValidators],
        // media: [null, []],
        // location: [null, []],
        invoiceVat: [null, RxwebValidators.required()],
        invoiceVatMargin: [null, RxwebValidators.required()],
        toNegotiation: [null, RxwebValidators.required()],
        leasingAssignment: [null],
        leasingInitialFee: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { console.log(x.leasingAssignment); return x.leasingAssignment == true } })],
        leasingMonthlyInstallment: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.leasingAssignment == true } })],
        leasingRemainingInstallments: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.leasingAssignment == true } })],
        leasingBuyoutValue: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.leasingAssignment == true } })],
        vin: [null, RxwebValidators.required()],
        mileage: [null, RxwebValidators.required()],
        registrationPlate: [null, RxwebValidators.required()],
        firstRegistrationInCountry: [null, RxwebValidators.required()],
        currentColour: [null, RxwebValidators.required()],
        originalColour: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.originalPaintDiffersCurrent == true } })],
        originalPaintDiffersCurrent: [false],
        hasPaintCoverage: [false],
        hasPpfWrapCoverage: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.hasPaintCoverage == true } })],
        hasOtherWrapCoverage: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.hasPaintCoverage == true } })],
        hasOtherPaintCoverage: [null, RxwebValidators.required({ conditionalExpression: (x, y) => { return x.hasPaintCoverage == true } })],
        paintCoverageDescription: [null],
        imported: [null],
        accidentFree: [null],
        damaged: [null],
        rightSideSteeringWheel: [null],
        registeredInCountry: [null],
        asoService: [null],
        registeredAsAntique: [null],
        mechanicalTuning: [null],
        visualTuning: [null],
        truckApproval: [null],
      }
    );

    // if (this.historyPostVM) {
    //   console.log(this.historyPostVM);
    //   this.form.setValue({
    //     heading: this.historyPostVM.heading,
    //     dateRange: [new Date(this.historyPostVM.startDate), new Date(this.historyPostVM.endDate)],
    //     content: this.historyPostVM.content
    //   });
    // }

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      } else {
        this.subject.next(new ModalStateEvent(ModalState.INVALID));
      }
    })
  }

  public submit(): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      const marketplaceAdvertisementAM = MarketplaceAdvertisementApiModel.fromMarketplaceAdvertisementVM(this.createAdvertisementVM());
      const files = this.mediaUploaderComponent.newFiles;
      this.mediaUploadService.uploadMedia(files).subscribe(({ mediaObjects$, progress$ }) => {
        progress$.subscribe((progress) => {
          this.mediaProgress = progress; // Aktualizacja procentowego postępu
        });

        mediaObjects$.subscribe((uploadedMedia: MediaObjectApiModel[]) => {
          const newMedia = uploadedMedia.map((media: MediaObjectApiModel) => media['@id']);
          const existingMedia = this.mediaUploaderComponent.mediaObjectAMs.map(m => m['@id']) as string[];
          marketplaceAdvertisementAM.media = [...existingMedia, ...newMedia];
          this.marketplaceAdvertisementRS.create(marketplaceAdvertisementAM).subscribe({
            next: () => {
            this.subject.next(new ModalStateEvent(ModalState.SUCCESS, marketplaceAdvertisementAM));
            },
            error: (e) => {
              console.log(e);
              this.isSubmitted = false;
              this.mediaProgress = null;
            }
          });
        });
      });

      // this.marketplaceAdvertisementRS.create(marketplaceAdvertisementAM).subscribe(() => {
      //   this.subject.next(new ModalStateEvent(ModalState.SUCCESS, marketplaceAdvertisementAM));
      // })
    }
  }

  clickNext(step: string) {
    console.log(step, this.selectedProjectVM, this.form);

    this.activeTab = step;
  }

  nextStep(step: string): string {
    switch (step) {
      case 'register':
        return 'tier';
      case 'tier':
        if (this.form.invalid) {
          this.form.markAllAsTouched();
          this.form.updateValueAndValidity();
          return 'tier';
        }
        this.createAdvertisementVM();
        return 'payment';
      case 'payment':
        return 'register';
    }
  }

  private createAdvertisementVM(): AdvertisementViewModel {
    const formValues = this.form.value;

    const vehicleAttributes: VehicleAttributes = {
      vin: formValues.vin,
      registrationPlate: formValues.registrationPlate,
      firstRegistrationInCountry: formValues.firstRegistrationInCountry,
      originalColour: formValues.originalColour?.value,
      currentColour: formValues.currentColour?.value,
      originalPaintDiffersCurrent: formValues.originalPaintDiffersCurrent,
      hasPaintCoverage: formValues.hasPaintCoverage,
      hasPpfWrapCoverage: formValues.hasPpfWrapCoverage,
      hasOtherWrapCoverage: formValues.hasOtherWrapCoverage,
      hasOtherPaintCoverage: formValues.hasOtherPaintCoverage,
      paintCoverageDescription: formValues.paintCoverageDescription,
      mileage: formValues.mileage,
      imported: formValues.imported,
      accidentFree: formValues.accidentFree,
      damaged: formValues.damaged,
      rightSteeringWheel: formValues.rightSideSteeringWheel,
      registeredInCountry: formValues.registeredInCountry,
      asoService: formValues.asoService,
      registeredAsAntique: formValues.registeredAsAntique,
      mechanicalTuning: formValues.mechanicalTuning,
      visualTuning: formValues.visualTuning,
      truckApproval: formValues.truckApproval,
    };

    const projectAM: ProjectApiModel = this.selectedProjectVM.toApiModel() || new ProjectApiModel();
    projectAM.vin = formValues.vin;
    projectAM.registrationPlate = formValues.registrationPlate;
    projectAM.firstRegistrationInCountry = formValues.firstRegistrationInCountry;
    projectAM.attributes = vehicleAttributes;
    projectAM.equipment = this.filterSelectedEquipment().map(e => e['@id']) as string[];

    return {
      title: formValues.title,
      description: formValues.description,
      address: formValues.address,
      price: formValues.price,
      currency: formValues.currency.value,
      priceValue: formValues.priceValue,
      invoiceVat: formValues.invoiceVat,
      invoiceVatMargin: formValues.invoiceVatMargin,
      toNegotiation: formValues.toNegotiation,
      leasingAssignment: formValues.leasingAssignment,
      visibleHistory: this.showHistory,
      visibleCosts: this.showInvestments,
      visibleRankingPosition: this.showRankingPosition,
      visibleProfileLink: this.showProfileLink,
      showParametersModifications: this.showModifiedParameters,
      anonymous: this.isAnonymousAdvertisement,
      relatedToExistingVehicle: !this.isRelatedToExistingVehicle,
      project: projectAM,
      type: this.advertisementType,
      period: this.advertisementPublicationPeriod,
    };
  }


  selectTier(card: string) {
    this.activeCard = card;
    this.activeTab = 'payment';
  }

  searchProjects(val: string) {
    this.filteredProjects = this.projectVMs.filter(e => {
      return (e.alias && e.alias.includes(val)) || e.vehicle.fullVehicleIdentity.includes(val);
    })
  }

  getLabel(result: any) {
    // console.log(this.selectedProjectVM);
    return result.vehicle.fullVehicleIdentity + (result.alias ? ' | ' + result.alias : '')
  }

  configureVehicle(vehicleVM: VehicleViewModel) {
    this.selectedProjectVM = new ProjectViewModel();
    this.selectedProjectVM.vehicle = vehicleVM;
    this.selectedProjectVM.user = this.currentUserVM;
    this.selectedProjectVM.status = GarageStatusEnum.ON_SALE;
  }

  processSelectedProject(): void {
    this.parent.name = this.initialAdvertisementName + ' dot. ' + this.selectedProjectVM.vehicle.fullVehicleIdentity;
  }

  processSelectedColour(event): void {
    this.selectedColour = event.value;
  console.log(this.selectedColour, event);
  }

  searchPlaces(event: any) {
    const sub = this.mapService.subscribeToQuerySubject().subscribe((results: any[]) => {
      this.searchResults = results.map(result => ({label: result.display_name, value: result}));
      if (!this.searchResults.length) {
        this.form.get('address').setErrors({incorrect: true, message: 'This is not valid place'});
      }
      sub.unsubscribe();
    });
    this.mapService.searchPlaces(event.query);
  }

  selectPlace(event: AutoCompleteSelectEvent) {
    const item: any = event.value.value;
    const iMarker = {lat: item.lat as number, lon: item.lon as number};

    if (this.mapComponent.layerMarkers.length) {
      this.mapComponent.removeMarker(this.mapComponent.layerMarkers[this.mapComponent.layerMarkers.length - 1]);
    }
    this.mapComponent.addMarker(iMarker);
    this.mapComponent.updateMapCenter(iMarker);
    const addrInput = this.form.get('address');
    // addrInput.setValue(event.value);
    addrInput.setErrors(null);
  }

  getAddrOptionValue(item: SelectItem): any {
    return item.value;
  }

  public filterSelectedEquipment(): EquipmentApiModel[] {
    return this.equipmentCategoryCollection
      .flatMap(category => category.equipment) // Wyciąga wszystkie elementy `equipment` z każdej kategorii
      .filter(equipment => equipment.selected); // Filtruje tylko te, które są `selected`
  }
}

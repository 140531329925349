import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'currentLocale',
  pure: true
})
export class CurrentLocalePipe implements PipeTransform {


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService
  ) {}

  transform(val: any): string {
    return this.translateService.currentLang || this.locale;
  }
}

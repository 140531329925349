import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Location} from '@angular/common';

import {ProjectViewModel} from '../../../features/user/garage/project.view-model';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../modal/modal.service';
import {Camera} from '@capacitor/camera';
import {PostTypeEnum} from '../../../core/enums/post-type.enum';
import {PostViewModel} from '../../timeline/post.view-model';
import {TimelineComponent} from '../../timeline/timeline.component';
import {CurrentUserViewModel} from '../../../core/models/current-user.view-model';
import {TokenDataProviderService} from '../../../core/services/token-data-provider.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EventApiModel} from '../../../api/models/event.api.model';
import {CustomCameraMediaItem} from '../../../features/custom-camera/custom-camera';
import {CustomCameraService} from '../../../features/custom-camera/custom-camera.service';
import {ActionsService} from '../../footer/actions.service';
import {ViewTypeEnum} from '../../../core/enums/view-type.enum';


export type MediaFromType = 'cameraMedia' | 'deviceMedia';

@Component({
  selector: 'app-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss']
})
export class MediaGridComponent implements OnInit, AfterViewInit, OnDestroy {
  get ownerPermissions(): string[] {
    return this._ownerPermissions;
  }

  @Input() set ownerPermissions(value: string[]) {
    this._ownerPermissions = value;
  }
  get type(): ViewTypeEnum {
    return this._type;
  }

  @Input() set type(value: ViewTypeEnum) {
    this._type = value;
  }

  get items(): MenuItem[] {
    return this._items;
  }

  set items(value: MenuItem[]) {
    this._items = value;
  }

  @Input() public postTypes: PostTypeEnum[] = [];
  @Input() public projectVM: ProjectViewModel;
  @Input() public eventAM: EventApiModel;
  @Output() public isList: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(TimelineComponent) timeline: TimelineComponent;
  public isSidebarOpened = false;
  public sidebarAction: MediaFromType|null;
  public posts: PostViewModel[]|{isPlaceholder: boolean}[] = [];
  public startIndex = 0;
  public currentUser: CurrentUserViewModel;
  private _type: ViewTypeEnum = ViewTypeEnum.GRID;
  private _items: MenuItem[] = [];
  private _ownerPermissions: string[];

  constructor(
    public readonly actionsService: ActionsService,
    private readonly translate: TranslateService,
    private readonly ms: ModalService,
    private readonly tokenDPS: TokenDataProviderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly ccs: CustomCameraService,
    private readonly location: Location
  ) {
  }

  ngOnInit(): void {
    this.tokenDPS.currentUser.subscribe((u: CurrentUserViewModel) => this.currentUser = u);
    this.initMenuItems();
    this.handleViewQueryParameter();
    this.subscribeToAddAction();
    this.subscribeToViewChangeAction();
  }

  ngAfterViewInit() {
      this.actionsService.permissions = this.ownerPermissions;
  }

  ngOnDestroy(): void {
    this.closeContextMenu();
    this.actionsService.unsubscribeActions();
  }

  closeContextMenu(): void {
    this.isSidebarOpened = false;
    this.sidebarAction = null;
  }

  initMenuItems(): void {
    this.items = [
      {
        label: this.translate.instant('common.gallery'),
        icon: 'pi pi-fw pi-info-circle',
        command: async () => {
          this.closeContextMenu();
          await this.pickImages();
        },
      },
      {
        label: this.translate.instant('common.add_material'),
        icon: 'pi pi-fw pi-info-circle',
        command: () => {
          this.closeContextMenu();
          this.ms
            .showMediaModal({projectVM: this.projectVM, eventAM: this.eventAM, media: null, isImagePicked: false, timeline: this.timeline})
            .successState.subscribe(() =>  {
          });
        },
      }
    ];
  }

  public handleViewQueryParameter(): void {
    const {view} = this.route.snapshot.queryParams;
    if (view) {
      this.type = view;
      this.actionsService.viewType = this.type;
      // this.isList.emit(false);
    }
  }

  async pickImages() {
    const {photos} = await Camera.pickImages({
      quality: 100,
      limit: 1,
    });
    const media: CustomCameraMediaItem = await this.ccs.mediaItemFromFilePath(photos[0].webPath, 'image');
    this.ms
      .showMediaModal({projectVM: this.projectVM, eventAM: this.eventAM, media, isImagePicked: true, timeline: this.timeline})
      .successState.subscribe(() =>  {
    });
  }

  showAsList(): void {
    // this.router.navigate([], {queryParams: {view: ViewTypeEnum.LIST}}).then(() => {
      this.type = ViewTypeEnum.LIST;
      this.isList.emit(true);
    // });
    // this.removeHash();
    // this.type = 'list';
    // this.isList.emit(true);
  }

  showAsGrid(): void {
    // this.router.navigate([], {queryParams: {view: ViewTypeEnum.GRID}}).then(() => {
      this.type = ViewTypeEnum.GRID;
      this.isList.emit(false);
    // });
    // this.removeHash();
    // this.type = 'grid';
    // this.isList.emit(false);
  }

  private subscribeToViewChangeAction(): void {
    this.actionsService.subscribeToViewChangeAction(this.showAs.bind(this));
  }

  public subscribeToAddAction(): void {
    this.actionsService.subscribeToAddAction(() => this.isSidebarOpened = true);
  }

  private showAs(viewType: ViewTypeEnum): void {
    switch (viewType) {
      case ViewTypeEnum.GRID: return this.showAsGrid();
      case ViewTypeEnum.LIST: return this.showAsList();
    }
  }

  removeHash(): void {
    // const loc = window.location;
    // console.log(loc);
    // this.router.navigate([]);

    // if ('pushState' in history) {
    //   history.pushState('', document.title, loc.pathname);
    // } else {
    // }
  }
}

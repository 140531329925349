import {Expose, plainToClass, plainToClassFromExist, Transform, Type} from 'class-transformer';
import {AbstractApiModel} from './abstract.api.model';
import {MediaObjectApiModel} from './media-object.api-model';
import {AdvertisementViewModel} from "../../features/marketplace/advertisement.view-model";
import {ProjectApiModel} from "./project.api-model";
import {UserApiModel} from "./user.api-model";

export class MarketplaceAdvertisementApiModel extends AbstractApiModel {
  @Expose() public title?: string;
  @Expose() public description?: string;
  @Expose() public price?: string;
  @Expose() public priceGross?: string;
  @Expose() public priceNet?: string;
  @Expose() public currency?: string;
  @Expose() public priceValue?: string;
  @Expose() public slug?: string;

  @Expose()
  @Type(() => MediaObjectApiModel)
  public media?: MediaObjectApiModel[] | string[];

  @Expose() public addresses: {place: string, coordinates: number[], country: {name: string}, city: {coordinates: number[], name: string}, region: { name: string}}[];
  @Expose() public invoiceVat?: boolean;
  @Expose() public invoiceVatMargin?: boolean;
  @Expose() public toNegotiation?: boolean;
  @Expose() public leasingAssignment?: boolean;
  @Expose() public visibleHistory?: boolean;
  @Expose() public visibleCosts?: boolean;
  @Expose() public visibleRankingPosition?: boolean;
  @Expose() public visibleProfileLink?: boolean;
  @Expose() public showParametersModifications?: boolean;
  @Expose() public anonymous?: boolean;
  @Expose() public newVehicleAdvertisement?: boolean;
  @Expose()
  @Type(() => UserApiModel)
  public createdBy?: UserApiModel|string;
  @Expose()
  @Type(() => ProjectApiModel)
  public project?: ProjectApiModel;
  @Type(() => Date)
  @Expose() public updatedAt: Date;
  @Type(() => Date)
  @Expose() public createdAt: Date;
  @Type(() => Date)
  @Expose() public publishedAt: Date;
  @Expose() public type: string;
  @Expose() public visits: number;
  public isPlaceholder: boolean = false;

  public static fromMarketplaceAdvertisementVM(marketplaceAdvertisementVM: AdvertisementViewModel): MarketplaceAdvertisementApiModel {
    const marketplaceAdvertisementAM = plainToClass(MarketplaceAdvertisementApiModel, marketplaceAdvertisementVM);
    return marketplaceAdvertisementAM;
  }
}

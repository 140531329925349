import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ModalInterface} from '../../modal.interface';
import {Subscription, Observable, Subject} from 'rxjs';
import {ModalStateEvent} from '../../modal-state-event';
import {ResultsListTabInterface} from '../../../../core/components/search-results-list/results-list-tab.interface';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {ModalState} from '../../modal-state.enum';
import {SearchTabsEnum} from "../../../../core/components/search-results-list/search-tabs.enum";
import {VehicleTypeViewModel} from "../../../../core/models/vehicle-type.view-model";
import {MakeViewModel} from "../../../../core/models/make.view-model";
import {BodyTypeViewModel} from "../../../../core/models/body-type.view-model";
import {ModelViewModel} from "../../../../core/models/model.view-model";
import {BodyGenViewModel} from "../../../../core/models/body-gen.view-model";
import {EditionViewModel} from "../../../../core/models/edition.view-model";
import {VehicleViewModel} from "../../../../features/user/garage/vehicle.view-model";
import {VehicleDataProviderService} from "../../../services/vehicle.data-provider.service";
import {AbstractDictionaryViewModel} from "../../../../core/models/abstract-dictionary.view-model";
import {AbstractDictionaryApiModel} from "../../../../api/models/abstract-dictionary.api-model";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent implements OnInit, ModalInterface, OnDestroy {
  constructor(
    private readonly fb: RxFormBuilder,
    public vehicleDPS: VehicleDataProviderService,
  ) {
  }

  @Input() public data: {selectedTab: ResultsListTabInterface};
  public currentDate: Date = new Date();
  public form: UntypedFormGroup;
  public state: Observable<ModalStateEvent>;
  public subject: Subject<ModalStateEvent>;
  public vehicleTypes: VehicleTypeViewModel[] = [];
  public makes: MakeViewModel[];
  public bodyTypes: BodyTypeViewModel[];
  public models: ModelViewModel[];
  public bodyGens: BodyGenViewModel[];
  public editions: EditionViewModel[];
  public vehicle: VehicleViewModel;
  private subscription: Subscription;
  private _subject: Subject<ModalStateEvent> = new Subject<ModalStateEvent>();

  public readonly SearchTabsEnum = SearchTabsEnum;

  public ngOnInit(): void {
    this.createForm();
    this.subscribeToModalStates();
  }

  private subscribeToModalStates() {
    this.state = this._subject.asObservable();
    this.state.subscribe((mse: ModalStateEvent) => {
      console.log(mse);
      switch (mse.status) {
        case ModalState.PENDING:
          this.subject.next(new ModalStateEvent(ModalState.SUCCESS, null));
      }
    });
  }

  private createForm() {
    switch (this.data.selectedTab.type) {
      case SearchTabsEnum.PROJECTS:
        this.createVehicleFilterForm();
        break;
      case SearchTabsEnum.EVENTS:
        this.createEventFiltersForm();
        break;
      default:
        this.createEventFiltersForm();
        break;
    }

  }

  private createEventFiltersForm() {
    this.form = this.fb.group({
      address: new FormControl('', [RxwebValidators.required()]),
      distance: new FormControl(1000, [RxwebValidators.required()]),
      hashtags: new FormControl('', [RxwebValidators.required()]),
    });
  }

  public ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : null;
  }

  /**
   * THIS DECLARATION MUST BE IN THE SAME FLOW ORDER AS IN THE VIEW BECAUSE disableRelatedChoices METHOD WILL BROKE.
   */
  private createVehicleFilterForm(): void {
    this.form = this.fb.group({
      'project.vehicle.vehicleType': new UntypedFormControl([], []),
      'project.vehicle.make': new UntypedFormControl({value: '', disabled: true}, []),
      'project.vehicle.model': new UntypedFormControl({value: '', disabled: true}, []),
      // bodyType: new UntypedFormControl({value: '', disabled: true}, []),
      // bodyGen: new UntypedFormControl({value: '', disabled: true}, []),
      // edition: new UntypedFormControl({value: '', disabled: true}, []),
      'project.vehicle.bodyGen.yearFrom[gte]': new UntypedFormControl( null, []),
      'project.vehicle.bodyGen.yearTo[lte]': new UntypedFormControl( null, []),
      'project.attributes.mileage[gte]': new UntypedFormControl( null, []),
      'project.attributes.mileage[lte]': new UntypedFormControl( null, []),
      'priceGross[gte]': new UntypedFormControl( null, []),
      'priceGross[lte]': new UntypedFormControl( null, []),
    });

    this.seekVehicleTypes();
  }

  public seekVehicleTypes(): void {
    this.vehicleDPS.getVehicleTypeCollection()
      .subscribe((vehicleTypeVMs: VehicleTypeViewModel[]) =>  {
        this.vehicleTypes = vehicleTypeVMs;
      });
  }

  public seekMakes(vehicleTypeVMs: VehicleTypeViewModel[]): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('project.vehicle.make');
    this.unsubscribePending();
    this.subscription = this.vehicleDPS.getMakeCollection(vehicleTypeVMs).subscribe(
      (makeVMs: MakeViewModel[]) => {
        this.makes = makeVMs;
        this.form.controls['project.vehicle.make'].enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekBodyTypes(vm: AbstractDictionaryViewModel<AbstractDictionaryApiModel>[]): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('project.vehicle.bodyType');
    this.unsubscribePending();
    this.subscription = this.vehicleDPS.getBodyTypeCollection([vm]).subscribe(
      (bodyTypeVMs: BodyTypeViewModel[]) => {
        this.bodyTypes = bodyTypeVMs;
        this.bodyTypes.forEach(e => e.name === '' ? e.name = 'Unknown' : null);
        this.form.controls['project.vehicle.bodyType'].enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekModels(vehicleTypeVM: VehicleTypeViewModel[], makeVM: MakeViewModel[]): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('project.vehicle.model');
    this.unsubscribePending();
    this.subscription = this.vehicleDPS.getModelCollection([vehicleTypeVM, makeVM]).subscribe(
      (modelVMs: ModelViewModel[]) => {
        this.models = modelVMs;
        this.form.controls['project.vehicle.model'].enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekBodyGens(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel
  ): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('bodyGen');
    this.unsubscribePending();
    this.subscription = this.vehicleDPS.getBodyGenCollection(vehicleTypeVM, makeVM, bodyTypeVM, modelVM).subscribe(
      (bodyGenVMs: BodyGenViewModel[]) => {
        this.bodyGens = bodyGenVMs;
        this.form.get('bodyGen').enable();
        this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
      },
      error => {

      }
    );
  }

  public seekEditions(
    vehicleTypeVM: VehicleTypeViewModel,
    makeVM: MakeViewModel,
    bodyTypeVM: BodyTypeViewModel,
    modelVM: ModelViewModel,
    bodyGenVM: BodyGenViewModel
  ): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.disableRelatedChoices('edition');
    this.unsubscribePending();
    this.subscription = this.vehicleDPS.getEditionCollection(vehicleTypeVM, makeVM, bodyTypeVM, modelVM, bodyGenVM)
      .subscribe(
        (editionVMs: EditionViewModel[]) => {
          this.editions = editionVMs;
          this.form.get('edition').enable();
          this._subject.next(new ModalStateEvent(ModalState.PROCESS_END));
        },
        error => {

        }
      );
  }

  public seekVehicle(): void {
    this._subject.next(new ModalStateEvent(ModalState.PROCESS));
    this.unsubscribePending();
    this.vehicleDPS.getVehicleByParams(this.form.value)
      .subscribe((vehicles: VehicleViewModel[]) => {
        const vehicleVM = vehicles[0];
        vehicleVM.vehicleType = this.form.get('project.vehicle.vehicleType').value;
        // this.onVehicleSelected.emit(vehicleVM);
        this._subject.next(new ModalStateEvent(ModalState.VALID, vehicleVM));
      });
  }

  /**
   * TODO: Refactor this, as this method require specific declaration flow of form fields;
   * @param {string} startField
   */
  private disableRelatedChoices(startField: string): void {
    let flag = false;
    for (const key in this.form.controls) {
      if (key === startField) {
        flag = true;
      }
      if (flag) {
        this.form.controls[key].reset();
        this.form.controls[key].disable({onlySelf: true, emitEvent: false});
      }
    }
  }

  private unsubscribePending(): void {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  public getFilters(): HttpParams {
    return this.vehicleDPS.getFilters(this.form.value);
  }
}

import {Exclude, Expose, Type} from "class-transformer";
import {AbstractDictionaryApiModel} from "./abstract-dictionary.api-model";
import {EquipmentApiModel} from "./equipment.api-model";

@Exclude()
export class EquipmentCategoryApiModel extends AbstractDictionaryApiModel {
  @Type(() => EquipmentApiModel)
  @Expose() public equipment: EquipmentApiModel[];
  public selected: boolean = false;
}

import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from "primeng/api";

@Pipe({
  name: 'translateCollectionOrItem',
  standalone: true,
  pure: false
})
export class TranslateCollectionOrItemPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform<T>(input: T): T {
    if (Array.isArray(input)) {
      return input.map(item => this.translateItem(item)) as T;
    } else {
      return this.translateItem(input) as T;
    }
  }

  private translateItem(item: any): any {
    if (this.isSelectItem(item)) {
      return {
        ...item,
        label: this.translateService.instant(item.label),
      };
    } else if (typeof item === 'string') {
      return this.translateService.instant(item);
    }
    return item;
  }

  private isSelectItem(item: any): item is SelectItem {
    return item && typeof item.label === 'string' && 'value' in item;
  }
}

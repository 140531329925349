import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localeCurrency',
  pure: true
})
export class LocaleCurrencyPipe implements PipeTransform {
  private currencyMap: Record<string, string> = {
    'en-US': 'USD',
    'en-GB': 'GBP',
    'de-DE': 'EUR',
    'pl-PL': 'PLN'
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService
  ) {}

  transform(val: any): string {
    const lang = this.translateService.currentLang || this.locale;

    return this.currencyMap[lang] || 'USD';
  }
}

import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'distanceUnit',
  pure: true
})
export class DistanceUnitPipe implements PipeTransform {
  private unitMap: Record<string, string> = {
    'US': 'common.miles',
    'GB': 'common.miles',
    'UK': 'common.miles',
    'CA': 'common.miles',
    'PL': 'common.kilometers',
    'DE': 'common.kilometers',
    'FR': 'common.kilometers',
    'ES': 'common.kilometers',
    'IT': 'common.kilometers',
    'EU': 'common.kilometers'
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService
  ) {}

  transform(val: any): string {
    const countryCode = (this.translateService.currentLang || this.locale).split('-').pop()?.toUpperCase() || 'PL';

    const translationKey = this.unitMap[countryCode] || 'common.kilometers';

    return this.translateService.instant(translationKey);
  }
}

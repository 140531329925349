<!--<p>{{'shared.modal.component.add_vehicle.choose_vehicle' | translate}}</p>-->
<form *ngIf="data.selectedTab.type === SearchTabsEnum.EVENTS" [formGroup]="form" novalidate>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Lokalizacja" styleClass="pb-3">
        <p-autoComplete
          formControlName="address"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [minLength]="2"
          [delay]="500"
          [field]="'display_name'"
          [required]="true"
          [styleClass]="'mb-2'"
        >
          <ng-template let-result pTemplate="item">
            <div class="text-white">{{ result.label }}</div>
          </ng-template>
        </p-autoComplete>
        <app-map></app-map>
      </p-fieldset>
    </div>
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Odległość">
        <label><span> +{{form.get('distance').value}} km</span></label>
        <br/>
        <br/>
        <p-slider formControlName="distance" [step]="10" [max]="5000" styleClass="w-full mb-2"></p-slider>
      </p-fieldset>
    </div>
  </div>
  <div class="grid ">
    <div class="col-12 lg:col-6 p-fluid m-0 p-0 mb-2">
      <p-fieldset legend="Hashtagi">
        <app-hashtags
          formControlName="hashtags"
          [placeholder]="'shared.modal.modal_service.add_event.search'| translate"
          [completeOnFocus]="true"
          [forceSelection]="true"
        >
          <div *ngIf="form.get('hashtags').invalid && form.get('hashtags').touched" class="app-form-error">
            <p>{{form.get('hashtags')['errorMessage']}}</p>
            <p *ngIf="form.get('hashtags').errors?.custom">{{form.get('hashtags').errors.custom}}</p>
          </div>
        </app-hashtags>
      </p-fieldset>
    </div>
  </div>
</form>

<form *ngIf="data.selectedTab.type === SearchTabsEnum.PROJECTS" [formGroup]="form" novalidate>
  <div class="grid">
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-multiSelect
          formControlName="project.vehicle.vehicleType"
          filter="true"
          dataKey="@id"
          optionLabel="name"
          [showClear]="true"
          [autofocus]="false"
          [autofocusFilter]="false"
          [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.vehicle_type' | translate"
          [options]="vehicleTypes"
          (onChange)="seekMakes(vType.value)"
          #vType
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-multiSelect
          formControlName="project.vehicle.make"
          filter="true"
          dataKey="@id"
          optionLabel="name"
          [showClear]="true"
          [autofocus]="false"
          [autofocusFilter]="false"
          [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.make' | translate"
          [options]="makes"
          (onChange)="seekModels(vType.value, make.value)"
          #make
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="p-field">
        <p-multiSelect
          formControlName="project.vehicle.model"
          filter="true"
          dataKey="@id"
          optionLabel="name"
          [showClear]="true"
          [autofocus]="false"
          [autofocusFilter]="false"
          [placeholder]="'shared.modal.component.add_vehicle.form.placeholder.model' | translate"
          [options]="models"
          #model
        >
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="grid">
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                mode="decimal"
                formControlName="project.vehicle.bodyGen.yearFrom[gte]"
                [showClear]="true"
                [min]="1900"
                [max]="currentDate|date:'YYYY'"
                [maxlength]="4"
                [useGrouping]="false"
                [suffix]="' ' + ('common.year_short'|translate)"
              />
              <label>{{'common.year_from'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                mode="decimal"
                formControlName="project.vehicle.bodyGen.yearTo[lte]"
                [showClear]="true"
                [min]="1900"
                [max]="currentDate|date:'YYYY'"
                [maxlength]="4"
                [useGrouping]="false"
                [suffix]="' ' + ('common.year_short'|translate)"
              />
              <label>{{'common.year_to'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="grid">
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                mode="decimal"
                formControlName="project.attributes.mileage[gte]"
                [showClear]="true"
                [suffix]="' ' + (''|distanceUnit)"
                [locale]="''|currentLocale"
              />
              <label>{{'common.mileage_from'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                mode="decimal"
                formControlName="project.attributes.mileage[lte]"
                [showClear]="true"
                [suffix]="' ' + (''|distanceUnit)"
                [locale]="''|currentLocale"
              />
              <label>{{'common.mileage_to'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 p-fluid">
      <div class="grid">
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                formControlName="priceGross[gte]"
                mode="currency"
                [showClear]="true"
                [currency]="''|localeCurrency"
                [locale]="''|currentLocale"
              />
              <label>{{'common.price_from'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
        <div class="col-6 p-fluid">
          <div class="p-field">
            <p-floatLabel>
              <p-inputNumber
                formControlName="priceGross[lte]"
                mode="currency"
                [showClear]="true"
                [currency]="''|localeCurrency"
                [locale]="''|currentLocale"
              />
              <label>{{'common.price_to'|translate}}</label>
            </p-floatLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

